import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "../JobStyle.module.css";
import FrequencyList from "./FrequencyList";
import WorkFlowHistory from "../JobBoard/WorkFlowHistory/WorkFlowHistory";

export default function TaskFrequencyContainer({ jobId, selectedTask, selectedJobDetails }) {

  const [historyKey, setHistoryKey] = useState(1);

  const refreshHistory = useCallback(() => {
    setHistoryKey(v => v+1)
  }, [setHistoryKey])

  return (
    <div>
      <div className={`${styles.JobTasksListContainer}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7 col-lg-12 pr-0">
              {selectedTask ?
                <FrequencyList
                  jobId={jobId}
                  selectedTask={selectedTask}
                  selectedJobDetails={selectedJobDetails}
                  refreshHistory={refreshHistory}
                /> : null}
            </div>
            <div className="col-xl-5 col-lg-12 pl-0">
              <WorkFlowHistory jobId={jobId} key={historyKey} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}