import React, { useMemo, useState } from "react";
import { Modal } from "antd";
import styles from "../../../JobStyle.module.css";
import ApproveTaskModal from "./ApproveTaskModal";
import CloseIcon from '@mui/icons-material/Close';

export default function ApproveTask({ task, frequency, refreshJobBoard, jobId }) {
  const [approveModal, setApproveModal] = useState(false)

  const isDisabled = useMemo(() => {
    let value = false;
    if (frequency && frequency.job_board_task_state && frequency.job_board_task_state.toLowerCase() === "values approved for invoice") {
      value = true;
    }
    if (frequency && !frequency.task_invoice_id) {
      value = true;
    }
    if (frequency && frequency.freq_status && !(frequency.freq_status.toLowerCase() === "completed")) {
      value = true;
    }
    return value;
  }, [frequency]);

  return (
    <div>
      <button
        className={`${styles.PrimaryButton}`}
        onClick={() => { setApproveModal(true) }}
        disabled={isDisabled}
      >
        Approve Task Value for Invoice
      </button>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={approveModal}
        onCancel={() => setApproveModal(false)}
        bodyStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
        afterClose={() => { }}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <ApproveTaskModal jobId={jobId} frequency={frequency} task={task} onClose={() => setApproveModal(false)} refreshJobBoard={refreshJobBoard} />
      </Modal>
    </div>
  )
}