import React from "react";
import styles from "../JobStyle.module.css";
import { currencyFormat } from "../../../../utils/common";

export default function FrequencyHeader({ frequency, onActive }) {
  return (
    <div className={`${styles.TaskHeader}`} onClick={() => onActive()}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4">
            <div className={styles.PrimaryText}>
              <div className="font-weight-bold">
                {frequency.quote_task_label} <br />
                {frequency.task_name}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className={styles.Label}>Assigned To</div>
            <div>
              {frequency && frequency.assignee_name ? frequency.assignee_name : " - "}
            </div>
          </div>
          <div className="col-4">
            <div className={styles.Label}>Task Value</div>
            <div>
              {frequency && currencyFormat(frequency.task_amount) ? currencyFormat(frequency.task_amount) : " - "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}