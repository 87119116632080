import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { notification, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import moment from "moment";

// import { currencyFormat } from "../../../../utils/common";
import { getFrequencyInvoiceData, updateFrequencyStatus } from "../../../../actions/v2/JobActions";
// import { getJobTasks, getJobSiteTasks, getJobOnSiteInfo, getJobHeader } from "../../../../actions/v2/JobActions";
import ApproveTask from "../JobBoard/Tasks/ApproveTaskValue/ApproveTask";
import UploadInvoice from "../JobBoard/Tasks/UploadInvoice";
import InvoiceTable from "../JobBoard/Tasks/InvoiceTable";
import IssuePO from "../JobBoard/Tasks/IssuePO/IssuePO"; import { Strings } from "../../../../dataProvider/localize";

import { JobBoardStatusSelectStyle } from "../../Job/SelectV2SingleStyle";
import styles from "../JobStyle.module.css";
import { CircularProgress } from "@mui/material";
import Task from "../JobBoard/Tasks/Task";

const Frequency = ({ frequency, jobId, selectedTask, selectedJobDetails, refreshHistory, fetchFrequencies }) => {

  const dispatch = useDispatch();

  const [frequencyState, setFrequencyState] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);

  const controller = useRef();

  const frequencyStates = ["Booked", "In Progress", "Completed"];

  const isDisabledState = useCallback((state) => {
    let availableStates = [];
    let currentState = frequency && frequency.freq_status ? frequency.freq_status : "";

    switch (currentState) {
      case "Booked":
        availableStates.push("In Progress", "Completed");
        break;
      case "In Progress":
        availableStates.push("Completed")
        break;
      case "Completed":
        availableStates.push("In Progress");
        break;
      // case "Signed Off":
      //   availableStates.push("In Progress", "Completed");
      //   break;
      case "":
        availableStates = [];
        break;
      default: availableStates.push("Booked");
    }
    return state !== currentState && !availableStates.includes(state)
  }, [frequency]);

  const getInvoicesData = useCallback(() => {
    setLoadingInvoices(true);
    controller.current = new AbortController();
    dispatch(getFrequencyInvoiceData({ signal: controller.current.signal }, { quote_task_id: frequency.quote_task_id, job_id: frequency.job_id })).then(res => {
      if (res && res.length) {
        setInvoices(res);
      }
      setLoadingInvoices(false);
    }).catch(err => {
      if (err) {
        setLoadingInvoices(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }, [frequency.quote_task_id, frequency.job_id, setInvoices, setLoadingInvoices])

  const refreshJobBoard = useCallback(() => {
    getInvoicesData();
    refreshHistory();
    // if (jobId) {
    //   // props.onReload();
    //   Promise.all([
    //     dispatch(getJobHeader(jobId)),
    //     dispatch(getJobTasks(jobId)),
    //     dispatch(getJobSiteTasks(jobId)),
    //     dispatch(getJobOnSiteInfo(jobId))
    //   ])
    // }
  }, [getInvoicesData, refreshHistory])

  const onFrequencyStatusChange = useCallback((status) => {
    setLoading(true);
    let formData = {
      status: status,
      quote_task_label: frequency.quote_task_label,
      task_id: selectedTask.task_id,
      job_id: jobId,
      quote_task_id: frequency.quote_task_id,
      ...(frequency.task_invoice_id ? { task_invoice_id: frequency.task_invoice_id } : {}),
      ...(frequency.contractor_task_invoice_id ? { contractor_task_invoice_id: frequency.contractor_task_invoice_id } : {}),
      ...(frequency.assignee ? { assignee_id: frequency.assignee } : {})
    }
    dispatch(updateFrequencyStatus(formData)).then(message => {
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      });
      setVisible(false);
      fetchFrequencies();
      refreshHistory();
    }).catch(err => {
      setVisible(false);
      cancelFrequencyStateChange();
      notification.error({
        message: Strings.error_title,
        description: err ? err : Strings.generic_error,
        className: "ant-error"
      })
    }).finally(() => {
      setLoading(false);
    });
  }, [setLoading, dispatch, updateFrequencyStatus, setVisible, fetchFrequencies, refreshHistory, cancelFrequencyStateChange])

  const onFrequencyStateChange = useCallback((e) => {
    setFrequencyState(e.value);
    setVisible(true);
  }, [setFrequencyState, setVisible])

  const cancelFrequencyStateChange = useCallback(() => {
    setVisible(false)
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Scheduled");
    }
  }, [setFrequencyState, frequency])

  useEffect(() => {
    getInvoicesData();
  }, [frequency])

  useEffect(() => {
    if (frequency && frequency.freq_status) {
      setFrequencyState(frequency.freq_status);
    } else {
      setFrequencyState("Booked");
    }
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, [frequency])


  return (
    <div>
      <div className="container-fluid px-0">
        <hr className={`${styles.DetailsDivider} mt-0`}></hr>
        <div className="row align-items-top">
          {/* <div className="col-3">
          <div className={styles.PrimaryText}>
            <div className="font-weight-bold">
              {frequency.quote_task_label} <br />
              {frequency.task_name}
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className={styles.Label}>Value</div>
          <div>
            {frequency && currencyFormat(frequency.task_amount) ? currencyFormat(frequency.task_amount) : " - "}
          </div>
        </div> */}
          <div className="col-2">
            <div className={styles.Label}>Start Date</div>
            <div>{frequency && frequency.start_date ? moment(frequency.start_date).format("DD/MM/YYYY") : " - "}
            </div>
          </div>
          <div className="col-2">
            <div className={styles.Label}>End Date</div>
            <div>
              {frequency && frequency.end_date ? moment(frequency.end_date).format("DD/MM/YYYY") : " - "}
            </div>
          </div>
          <div className="col-5 text-right" style={{ paddingTop: "12px " }}>
            {loading ? (<CircularProgress size={20} />) : null}
          </div>
          <div className="col-3 text-center align-self-center" onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title={<div>
                <div className="font-weight-bold">Are you sure you want to change Status for this Frequency?</div>
                <div>Status Changes are not reversible.</div>
              </div>}
              visible={visible}
              onCancel={() => cancelFrequencyStateChange()}
              onConfirm={() => onFrequencyStatusChange(frequencyState)}
            >
              <Select
                value={{ label: frequencyState, value: frequencyState }}
                className={`w-100 text-${frequencyState ? frequencyState.toString().replace(/\s/gm, '').toLowerCase() : ""}`}
                onChange={onFrequencyStateChange}
                // isDisabled={invoiceStatus === 3 || (props.task && props.task.task_state && props.task.task_state.toString().toLowerCase() === "cancelled")}
                options={frequencyStates.map(state => ({
                  label: state, value: state,
                  isDisabled: isDisabledState(state)
                }))}
                styles={JobBoardStatusSelectStyle}
                otherProps={{
                  components: { dropdownIndicator: <components.DownChevron /> }
                }}
              />
            </Popconfirm>
          </div>
        </div>
        <hr className={styles.DetailsDivider}></hr>
      </div>
      <Task isDisabledJob={false} frequency={frequency} taskDetails={selectedTask} jobId={jobId} fetchFrequencies={fetchFrequencies} />
      <hr className={styles.DetailsDivider}></hr>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <IssuePO
              jobId={jobId}
              frequency={frequency}
              siteId={selectedTask.site_id}
              siteName={selectedTask.site_name}
              task={selectedTask}
              jobLabel={selectedJobDetails.label}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory() }} />
            <ApproveTask
              jobId={jobId}
              task={selectedTask}
              frequency={frequency}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory(); }} />
            <UploadInvoice
              contractor={selectedTask.contractor_details[0]}
              task={selectedTask}
              frequency={frequency}
              jobId={jobId}
              refreshJobBoard={() => { fetchFrequencies(); refreshHistory(); }} />
          </div>
        </div>
        <hr className={styles.DetailsDivider}></hr>
        <div className="row">
          <div className="col-12">
            <InvoiceTable
              refreshJobBoard={() => { getInvoicesData(); refreshHistory(); }}
              task={selectedTask}
              jobId={jobId}
              frequency={frequency}
              dataLoading={loadingInvoices}
              invoices={invoices}
            // isDisabled={props.task && props.task.job_board_task_state && props.task.job_board_task_state.toLowerCase() === "values approved for invoice"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Frequency);