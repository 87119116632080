import {
  SET_JOB_HEADER,
  SET_JOB_TASKS,
  SET_JOB_ON_SITE_INFO,
  SET_JOB_SITE_TASKS,
  SET_JOB_SWMS_ID,
  CLEAR_JOB_HEADER
} from "../../dataProvider/constant";

const INITIAL_STATE = {
  jobHeader: {},
  jobDetails: {},
  tasks: [],
  parentTasks: [],
  onSiteInfo: {},
  siteFiles: [],
  jobSWMSId: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_JOB_HEADER: {
      const { payload } = action;
      return {
        ...state,
        jobHeader: payload.header,
        jobSWMSId: payload.job_swms_id,
        swmsType: payload.swms_type
      }
    }
    case SET_JOB_TASKS: {
      const { payload } = action;
      return {
        ...state,
        jobDetails: payload,
        tasks: payload.tasks,
        parentTasks: payload.task_level_data && payload.task_level_data.length > 0 ? payload.task_level_data.map(x => ({...x, quote_task_id: x.task_id, task_number: x.task_label})) : []
      }
    }
    case SET_JOB_ON_SITE_INFO: {
      const { payload } = action;
      return {
        ...state,
        onSiteInfo: payload
      }
    }
    case SET_JOB_SITE_TASKS: {
      const { payload } = action;
      return {
        ...state,
        siteFiles: payload
      }
    }
    case SET_JOB_SWMS_ID: {
      const { payload } = action;
      return {
        ...state,
        jobSWMSId: payload
      }
    }
    case CLEAR_JOB_HEADER: {
      return {
        ...state,
        jobHeader: {},
        jobSWMSId: null
      }
    }
    default: {
      return state;
    }
  }
};